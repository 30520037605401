import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layouts";
import Hero from "../components/common/hero";
import Logo from "../components/common/logo";
import RatesPlan from "../components/rates/RatesPlan";
import StayIncludes from "../components/rates/StayIncludes";
import OffsiteExcursion from "../components/buyout/OffsiteExcursion";
import RegenrativeAdventure from "../components/rates/RegenrativeAdventure";
import BreadCrumb from "../components/common/breadcrumb";
import Instagram from "../components/common/Instagram";
import CompaniesGuided from "../components/common/companiesGuided";
import DetailsPolicies from "../components/rates/Details_Policies";
import Seo from "../components/seo";
import Faqs from "../components/home/faq";

const Buyout = ({ data: { sanityBuyoutPage, sanitySitesettings } }) => {
  let seo;
  let hero;
  let breadcrumb;
  let activiy;
  let polices;
  let offsite;
  let bookRegenerative;
  let faq;
  let guide;

  sanityBuyoutPage?.pageBuilder?.forEach((element) => {
    if (element._type === "seo") {
      seo = element;
    } else if (element._type === "heroSection") {
      hero = element;
    } else if (element._type === "breadcrumb") {
      breadcrumb = element;
    } else if (element._type === "activiy") {
      activiy = element;
    } else if (element._type === "polices") {
      polices = element;
    } else if (element._type === "bookRegenerative") {
      bookRegenerative = element;
    } else if (element._type === "companyGuided") {
      guide = element;
    } else if (element._type === "offsite") {
      offsite = element;
    } else if (element._type === "faq") {
      faq = element;
    }
  });

  return (
    <Layout>
      <Seo data={seo} />
      <Hero
        heroSection={hero}
        h1Size="text-[44px] leading-[70px] tracking-[2px]"
        h2Size="text-3xl sm:text-[50px] leading-[40px] sm:leading-[60px]"
      />
      <Logo logo={sanitySitesettings?.logo} />
      {sanityBuyoutPage?.rateList && (
        <RatesPlan
          data={sanityBuyoutPage?.rateList}
          h1Size="text-2xl sm:text-3xl leading-8 sm:leading-10"
        />
      )}
      {activiy && <StayIncludes data={activiy} h2Size="text-xl" />}
      {polices && (
        <DetailsPolicies
          data={polices}
          h1Size="text-3xl sm:text-[40px] text-secondary tracking-[2px]"
        />
      )}
      {offsite && <OffsiteExcursion data={offsite} />}
      {bookRegenerative && (
        <RegenrativeAdventure
          data={bookRegenerative}
          h1Size="text-3xl sm:text-[50px] leading-[50px] sm:leading-[80px]"
          h2Size="text-[22px] sm:text-3xl"
        />
      )}
      {faq?.heading && faq?.faqList.length > 0 && <Faqs data={faq} />}
      {breadcrumb && <BreadCrumb path={breadcrumb} />}
      <Instagram />
      {guide && (
        <>
          <CompaniesGuided data={guide} />
          <Logo logo={sanitySitesettings?.logo} />
        </>
      )}
    </Layout>
  );
};

export default Buyout;

export const query = graphql`
  query {
    sanityBuyoutPage {
      pageBuilder {
        ... on SanityFaq {
          _type
          heading
          faqList {
            question
            _rawAnswer
          }
        }
        ... on SanityHeroSection {
          _type
          heading1
          heading2
          alignment
          ctaButton {
            link
            title
            type
            variant
            formId
            downloadLink
          }
          bgImages {
            alt
            caption
            asset {
              gatsbyImageData(placeholder: BLURRED, fit: FILLMAX, formats: WEBP)
            }
          }
        }
        ... on SanitySeo {
          _type
          description
          keywords
          ldSchema
          pagehandle
          title
        }
        ... on SanityBreadcrumb {
          _type
          breadLink {
            breadPath
            nameLink
          }
          sectionBg {
            asset {
              url
            }
          }
        }

        ... on SanityCompanyGuided {
          _type
          heading
          sectionBg {
            asset {
              url
            }
          }
          list {
            name
          }
        }

        ... on SanityActiviy {
          _type
          heading
          sectionBG {
            asset {
              url
            }
          }
          activityItem {
            _rawContent
            heading
          }
        }
        ... on SanityOffsite {
          _type
          title
          sectionBg {
            asset {
              url
            }
          }
          offContent
        }
        ... on SanityPolices {
          _type
          title
          item {
            title
            rows {
              columns
              columnItem {
                _rawCol
                alignment
                width
              }
            }
          }
        }

        ... on SanityBookRegenerative {
          _type
          columns
          heading
          mainlink
          subheading
          svg
          bookItem {
            _rawDetail
            link
            svg
            title
          }
        }
      }
      rateList {
        heading
        subheading
        option
        duration
        rateImg {
          alt
          asset {
            gatsbyImageData(fit: FILLMAX, placeholder: BLURRED, formats: WEBP)
          }
        }
        options {
          bgColor
          price
          text1
          title
          _rawText2
          ctaButton {
            link
            title
            type
            variant
            formId
            downloadLink
          }
        }
        cType
        _rawDescription
      }
    }
    sanitySitesettings {
      logo {
        alt
        asset {
          gatsbyImageData(fit: FILLMAX, placeholder: BLURRED, formats: WEBP)
        }
      }
    }
  }
`;
